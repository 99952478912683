import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/logo.svg'


const _hoisted_1 = { class: "globalHeader" }

import { routes } from "@/router/route";
import { useRouter } from "vue-router";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalHeader',
  setup(__props) {

const router = useRouter();

const selectedKeys = ref(["/"]);
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});
const toMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_menu, {
      mode: "horizontal",
      "default-selected-keys": ['1'],
      onMenuItemClick: toMenuClick,
      "selected-keys": selectedKeys.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_menu_item, {
          key: "0",
          style: { padding: 0, marginRight: '38px' },
          disabled: ""
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "title-bar" }, [
              _createElementVNode("img", {
                class: "logo",
                src: _imports_0
              }),
              _createElementVNode("div", { class: "title" }, "工具箱")
            ], -1)
          ])),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(routes), (item) => {
          return (_openBlock(), _createBlock(_component_a_menu_item, {
            key: item.path
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["selected-keys"])
  ]))
}
}

})