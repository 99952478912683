import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_copy = _resolveComponent("icon-copy")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_icon_attachment = _resolveComponent("icon-attachment")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_content_field = _resolveComponent("content-field")!

  return (_openBlock(), _createBlock(_component_content_field, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_upload, {
        class: "upload-windows",
        draggable: "",
        action: _ctx.appConfig().apiUrl,
        "file-list": _ctx.filelist,
        onBeforeRemove: _ctx.beforeRemove,
        onBeforeUpload: _ctx.beforeUpload,
        onSuccess: _ctx.handleSuccess,
        "list-type": "picture",
        "show-preview-button": "",
        "show-remove-button": false
      }, {
        "extra-button": _withCtx(({ fileItem }) => [
          _createVNode(_component_a_tooltip, { content: "复制链接" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "transparent-button",
                onClick: ($event: any) => (_ctx.copyLink(fileItem))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_icon_copy)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_tooltip, { content: "复制 Markdown 链接" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                class: "transparent-button",
                onClick: ($event: any) => (_ctx.copyMarkdownLink(fileItem))
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_icon_attachment)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["action", "file-list", "onBeforeRemove", "onBeforeUpload", "onSuccess"])
    ]),
    _: 1
  }))
}