<template>
  <div id="app">
    <BasicLayout />
  </div>
</template>

<style></style>
<script>
import BasicLayout from "@/layouts/BasicLayout.vue";

export default {
  components: { BasicLayout },
};
</script>
